import {AxiosResponse} from 'axios';

import {CreateUpdateLoadParamsForApi, LoadApiGateway, MetaData} from 'core/useCases/Load/types';
import * as toRequests from 'core/gateways/TravelOrderApiGateway/requests/indexNew';

import getFilePath from 'utils/files/getFilePath';

import {FileNew as FileData} from 'types/File';
import GeneralNote from 'types/GeneralNote';

import {
    getLoad,
    createLoad,
    updateLoad,
    createTravelOrderWithCarrier,
    createTravelOrderWithTruck,
    addLoadGeneralNoteRequest,
    addLoadAllFiles,
    generateLoadBOL,
    generateLoadInvoice,
    cancelLoadRequest,
    changeLoadStatusToTONURequest,
    changeLoadStatusToNoTONURequest,
    changeLoadStatusToAvailableRequest,
    changeLoadStatusToWaitingForTONURequest,
    updateLoadFilesMetaRequest,
} from './requests';

const loadApiGateway: LoadApiGateway = {
    getLoad: (loadID) => {
        return getLoad(loadID).then((response) => response.data);
    },
    getLoadTravelOrder: (travelOrderID) => {
        return travelOrderID ? toRequests.fetchTravelOrder(travelOrderID) : undefined;
    },
    createLoad: (loadData: CreateUpdateLoadParamsForApi) => {
        return createLoad(loadData).then((response) => response.data);
    },
    createTravelOrderWithTruck: ({loadNumber, truckNumber}) => {
        return createTravelOrderWithTruck({truckNumber, loadNumber}).then((response) => response.data);
    },
    createTravelOrderWithCarrier: ({loadNumber, carrierId}) => {
        return createTravelOrderWithCarrier({carrierId, loadNumber}).then((response) => response.data);
    },
    updateLoadData: (changedLoad: CreateUpdateLoadParamsForApi) => {
        return updateLoad(changedLoad).then((response) => response.data);
    },
    updateLoadFilesMeta: (props) => {
        const {loadNumber, dataForRequest} = props;
        return updateLoadFilesMetaRequest(loadNumber, dataForRequest).then((response) => response.data);
    },
    addLoadFiles: (params) => {
        const {loadNumber, files} = params;
        return addLoadAllFiles(loadNumber, files).then((response) => response.data);
    },
    addLoadGeneralNote: (params): Promise<AxiosResponse<GeneralNote>> => {
        const {load, note, isImportant} = params;
        return addLoadGeneralNoteRequest(load.number, {note, isImportant});
    },
    generateLoadBol: (loadNumber: number): Promise<string> => {
        return generateLoadBOL(loadNumber).then(({data}) => getFilePath(data));
    },
    generateInvoice: (loadNumber: number, files: FileData[]): Promise<string> => {
        return generateLoadInvoice(loadNumber, files).then(({data}) => getFilePath(data));
    },
    cancelLoad: (
        loadNumber: number,
        params: {makeWaitingForTONU: boolean; note: string; isImportant: boolean},
    ): Promise<void> => {
        return cancelLoadRequest(loadNumber, params).then((response) => response.data);
    },
    changeLoadStatusToTONU: (loadNumber: number, params: {totalCharge: number; files?: MetaData[]}): Promise<void> => {
        return changeLoadStatusToTONURequest(loadNumber, params).then((response) => response.data);
    },
    changeLoadStatusToNoTONU: (loadNumber: number, params: {note: string; isImportant: boolean}): Promise<void> => {
        return changeLoadStatusToNoTONURequest(loadNumber, params).then((response) => response.data);
    },
    changeLoadStatusToAvailable: (loadNumber: number): Promise<void> => {
        return changeLoadStatusToAvailableRequest(loadNumber).then((response) => response.data);
    },
    changeLoadStatusToWaitingForTONU: (loadNumber: number): Promise<void> => {
        return changeLoadStatusToWaitingForTONURequest(loadNumber).then((response) => response.data);
    },
};

export default loadApiGateway;
