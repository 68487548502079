import {CROSS_SERVER_TRUCK_RESERVATION_CANCELED, CROSS_SERVER_TRUCK_RESERVATION_COMPLETED} from 'store/actionTypes';
import * as socketsActionTypes from 'store/middlewares/mainAPISocketsIO/entities/trucks/actionTypes';

import Truck from 'core/entities/Truck/types';

import {awaySortValues, SortBy} from 'pages/FullAlliance/constants';
import * as types from 'pages/FullAlliance/redux/actionTypes';
import * as mappers from 'pages/FullAlliance/redux/mappers';
import {FullAllianceSearchFormValues} from 'pages/FullAlliance/types/formTypes';

import {PlaceAddress} from 'types/Address';
import Pagination from 'types/Pagination';

type Sorting = {
    sortBy: SortBy;
};

type LocationPoints = {
    destination: PlaceAddress | null;
    origin: PlaceAddress | null;
};

export type ListState = {
    searchParams: Partial<FullAllianceSearchFormValues>;
    locationPoints: LocationPoints;
    pagination: Pagination;
    expandedIDs: number[];
    sorting: Sorting;
    items: Truck[];
};

const defaultListState: ListState = {
    pagination: {totalItemsCount: 0, currentPage: 1, pagesCount: 0, perPage: 50},
    locationPoints: {origin: null, destination: null},
    sorting: {sortBy: awaySortValues.origin},
    searchParams: {},
    expandedIDs: [],
    items: [],
};

const listReducer = (state = defaultListState, action): ListState => {
    switch (action.type) {
        case types.TRUCKS_RECEIVED: {
            return {...state, items: action.payload.items};
        }

        case types.PAGINATION_RECEIVED: {
            return {...state, pagination: action.payload.pagination};
        }

        case types.SEARCH_PARAMS_RECEIVED: {
            return mappers.handleReducerBySetSearchParams({state, formData: action.payload.searchParams});
        }

        case types.EXPANDED_IDS_RECEIVED: {
            return {...state, expandedIDs: action.payload.expandedIDs};
        }

        case types.LIST_SORT_BY_RECEIVED: {
            return {...state, sorting: {...state.sorting, sortBy: action.payload.sortBy}};
        }

        case types.LOCATION_POINTS_RECEIVED: {
            return {...state, locationPoints: {origin: action.payload.origin, destination: action.payload.destination}};
        }

        case socketsActionTypes.WEB_SOCKET_TRUCKS_RESERVE_RECEIVED: {
            return mappers.handleReducerByWSReserveReceived({state, reserveData: action.payload});
        }

        case socketsActionTypes.WEB_SOCKET_TRUCKS_RESERVE_CANCELED: {
            return mappers.handleReducerByWSReserveCanceled({state, truckID: action.payload});
        }

        case socketsActionTypes.WEB_SOCKET_TRUCKS_UPDATE_RECEIVED: {
            return mappers.handleReducerByWSReserveUpdateReceived({state, newTruck: action.payload});
        }

        case CROSS_SERVER_TRUCK_RESERVATION_COMPLETED: {
            return mappers.handleReducerByCrossServerReservationCompleted({state, reserveData: action.payload});
        }

        case CROSS_SERVER_TRUCK_RESERVATION_CANCELED: {
            return mappers.handleReducerByCrossServerReservationCanceled({state, reserveData: action.payload});
        }

        case types.LIST_STATE_CLEARED: {
            return {...defaultListState};
        }

        default:
            return state;
    }
};

export default listReducer;
