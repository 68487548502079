import {AppState} from 'store';

import {RegistryState} from '.';

export const getCurrency = (state: AppState): RegistryState['currency'] => state.registry?.currency;

export const selectUserRoleOptions = (state: AppState): RegistryState['userRoleOptions'] =>
    state.registry.userRoleOptions;

export const selectUserFilterRoleOptions = (state: AppState): RegistryState['userFilterRoleOptions'] =>
    state.registry.userFilterRoleOptions;

export const selectSupportedFileTypes = (state: AppState): RegistryState['supportedFileTypes'] =>
    state.registry.supportedFileTypes;

export const selectTravelOrderDefaultPresets = (state: AppState): RegistryState['travelOrderDefaultPresets'] =>
    state.registry.travelOrderDefaultPresets;

export const selectNetworksOptions = (state: AppState): RegistryState['networksOptions'] =>
    state.registry.networksOptions;

export const getNetworksBenefits = (state: AppState): RegistryState['networksBenefits'] =>
    state.registry.networksBenefits;

export const getTruckBasicEquipment = (state: AppState): RegistryState['truckBasicEquipment'] =>
    state.registry.truckBasicEquipment;

export const getTruckAdditionalEquipment = (state: AppState): RegistryState['truckAdditionalEquipment'] =>
    state.registry.truckAdditionalEquipment;
