import React from 'react';

import TooltipCustom from 'components/ui/Form/TooltipCustom';
import getValidationState from '../getValidationState';

const OverlayTriggerCustom = (props) => {
    const {children, placement, inputMeta = {}, inputMeta: {error = ''} = {}, className = ''} = props;
    const isValid = getValidationState(inputMeta);

    return (
        <div className={`${className} overlay-trigger-wrap`}>
            {children}

            {!isValid && (
                <TooltipCustom placement={placement || 'bottom'}>
                    <span>{error}</span>
                </TooltipCustom>
            )}
        </div>
    );
};

export default React.memo(OverlayTriggerCustom);
