import {AppState} from 'store';

import Truck from 'core/entities/Truck/types';
import {Note} from 'core/entities/Truck/types/Note';
import Driver from 'core/entities/Driver/types';

import Pagination from 'types/Pagination';
import GeneralNote from 'types/GeneralNote';

import {CurrentTruck, UpdateFormTab} from '../types';

export const selectTrucks = (state: AppState): Truck[] => state.trucks.list.items;
export const selectSearchParams = (state: AppState) => state.trucks.list.searchParams;
export const selectPagination = (state: AppState): Partial<Pagination> => state.trucks.list.pagination;
export const selectExpandedListRows = (state: AppState): number[] => state.trucks.list.expandedRows;
export const selectDrawerData = (state: AppState) => state.trucks.list.drawerTruckNotes;
export const selectGeneralTruckNotes = (state: AppState): Note[] => state.trucks.list.generalTruckNotes;
export const selectCurrentTruck = (state: AppState): CurrentTruck | undefined => state.trucks.current.truckData;
export const selectCurrentTruckNotes = (state: AppState): GeneralNote[] | undefined => {
    const currentTruck = selectCurrentTruck(state);
    if (!currentTruck) {
        return;
    }
    return currentTruck.truck_notes;
};
export const selectCurrentTruckInsuranceExpiration = (state: AppState): string | undefined | null =>
    state.trucks.current.truckData?.insurance_expiration;
export const selectCurrentTruckNumber = (state: AppState): string | undefined => state.trucks.current.truckData?.number;
export const selectCurrentTruckStatus = (state: AppState): string | undefined => state.trucks.current.truckData?.status;
export const selectCurrentTruckDrivers = (state: AppState): Driver[] | undefined =>
    state.trucks.current.truckData?.drivers;
export const selectTruckUpdateActiveTab = (state: AppState): UpdateFormTab | undefined =>
    state.trucks.current.update.activeTab;
