import React from 'react';
import {Form, FormControl, FormGroup, FormLabel} from 'react-bootstrap';
import {submit} from 'redux-form';

import Button from 'components/ui/Buttons/Button';

import {handleKeyDown} from 'utils/form/handleKeyDown';

import getValidationState from '../getValidationState';
import OverlayTriggerCustom from '../OverlayTriggerCustom';

import styles from './styles.module.scss';

export default (props) => {
    const {
        meta,
        meta: {asyncValidating, dispatch, form},
        label,
        errorTooltipPlacement,
        formGroup = true,
        bsPrefix,
        maxLength,
        clearable,
        withSubmitBtn,
        keyDownWithoutCheckForRepeat,
    } = props;

    const value = props.value || props.input.value;
    const ref = props.ref || props.input.ref;
    const onChange = props.onChange || props.input.onChange;
    const onBlur = props.onBlur || props.input.onBlur;
    const onKeyDown = props.onKeyDown || props.input.onKeyDown;
    const disabled = props.disabled || props.input.disabled;
    const type = props.type || props.input.type;
    const {autoFocus, placeholder: placeHolder} = props;
    const valid = getValidationState(meta);

    const input = (
        <FormControl
            {...props.input}
            autoFocus={autoFocus}
            maxLength={maxLength}
            placeholder={placeHolder}
            bsPrefix={bsPrefix}
            // off by default
            autoComplete={props.autocomplete || 'off'}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={keyDownWithoutCheckForRepeat ? onKeyDown : handleKeyDown}
            ref={ref}
            disabled={disabled || asyncValidating}
            type={type}
        />
    );

    const clearValue = () => {
        props.input.onChange('');
    };

    const errorGroupClass = valid ? '' : 'has-feedback has-error';

    if (formGroup) {
        return (
            <FormGroup className={`${styles.text_input} ${errorGroupClass}`}>
                {label && <FormLabel>{label}</FormLabel>}
                <OverlayTriggerCustom inputMeta={!disabled && meta} placement={errorTooltipPlacement}>
                    {input}
                </OverlayTriggerCustom>
                {clearable && value && (
                    <div className="clear-btn">
                        <Button buttonIcon="times" buttonSize="icon" onClick={clearValue} />
                    </div>
                )}

                {withSubmitBtn && value && (
                    <div className="submit-btn">
                        <Button buttonIcon="search" buttonSize="icon" onClick={() => dispatch(submit(form))} />
                    </div>
                )}

                {!clearable && !disabled && (
                    <Form.Control.Feedback type="invalid" className={valid ? '' : 'error'}>
                        <i className="fa fa-exclamation-circle" />
                    </Form.Control.Feedback>
                )}
            </FormGroup>
        );
    }

    return input;
};
