import React from 'react';
import {Field} from 'redux-form';

import DropDownCheckBoxMulti from 'components/ui/Form/DropDownCheckBoxMulti';

import {loadTypesOptions} from './utils';

interface OwnProps {
    name: string;
}

const LoadTypeCheckBoxMulti: React.FC<OwnProps> = (props) => {
    const {name} = props;

    return <Field name={name} btnTitle="Load Type" component={DropDownCheckBoxMulti} items={loadTypesOptions} />;
};

export default LoadTypeCheckBoxMulti;
