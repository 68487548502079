import {AppState} from 'store';
import {getPagePermissions} from 'store/reducers/userPermissions/selectors';

import * as pages from 'utils/data/pages';
import permissionsFields from 'utils/data/permissions';

export const getCurrentDriver = (state: AppState) => state.drivers.current.current;
export const getInitialDataForCreateDriver = (state: AppState) => state.drivers.current.initialData;
export const getListDrivers = (state: AppState) => state.drivers.list.list;
export const getFormActiveTab = (state: AppState) => state.drivers.current.activeTab;
export const getListSearchParams = (state: AppState) => state.drivers.list.searchParams;
export const getListPagination = (state: AppState) => state.drivers.list.pagination;
export const getListTab = (state: AppState) => state.drivers.list.activeListTab;
export const getDriversPermissions = (state: AppState) => getPagePermissions(state, pages.DRIVERS);

export const getPermissionList = (state: AppState) => {
    const permissions = getDriversPermissions(state);
    return permissions[permissionsFields.list] !== false;
};
export const getPermissionCreate = (state: AppState) => {
    const permissions = getDriversPermissions(state);
    return permissions[permissionsFields.create] !== false;
};
export const getPermissionView = (state: AppState) => {
    const permissions = getDriversPermissions(state);
    return permissions[permissionsFields.view] !== false;
};
export const getPermissionUpdate = (state: AppState) => {
    const permissions = getDriversPermissions(state);
    return permissions[permissionsFields.update] !== false;
};
export const getPermissionDelete = (state: AppState) => {
    const permissions = getDriversPermissions(state);
    return permissions[permissionsFields.delete] !== false;
};
