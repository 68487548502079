import type {CreateLoadParams, CreateLoadResult, LoadApiGateway} from '../types';
import transformLoadDataToRequest from './transformLoadDataToRequest';
import {transformMetaDataToRequest} from '../changeLoadFiles/utils';
import {changeLoadFiles as changeLoadFilesUseCase} from '../index';

const createLoad = async function (
    data: CreateLoadParams,
    deps: {loadApiGateway: LoadApiGateway},
): Promise<CreateLoadResult> {
    const {loadData, addedFiles} = data;
    const {loadApiGateway} = deps;

    const metaLoadFiles = transformMetaDataToRequest(addedFiles);
    const convertedLoad = transformLoadDataToRequest({load: loadData, metaLoadFiles});

    const {loadNumber, files, hasAccess} = await loadApiGateway.createLoad(convertedLoad);

    await changeLoadFilesUseCase(loadNumber, {addedFiles, fileIds: files});

    if (!hasAccess) {
        return {loadNumber, hasAccessToView: hasAccess};
    }

    const createdLoad = await loadApiGateway.getLoad(loadNumber);

    return {loadNumber, createdLoad, hasAccessToView: hasAccess};
};

export default createLoad;
