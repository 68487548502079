import React from 'react';
import {WrappedFieldProps} from 'redux-form';
import MaskedInput from 'react-text-mask';
import {FormLabel, Form, FormGroup} from 'react-bootstrap';
import classNames from 'classnames';

import {changeDate, getCurrentDate, getCurrentDateSettings} from 'utils/dateTime';

import OverlayTriggerCustom from 'components/ui/Form/OverlayTriggerCustom';
import getValidationState from 'components/ui/Form/getValidationState';

import {generateDateTimeMask} from '../../utils';
import {CustomInputProps} from '../../types';

const CustomInputWrapper = (props: CustomInputProps & WrappedFieldProps, ref): JSX.Element | null => {
    const {
        meta,
        label,
        formGroup = true,
        redesign,
        disabled,
        placeHolder,
        showSideButtons,
        isEditableFromKeyBoard,
        onClickSideButtons,
        dateFormat,
    } = props;

    const {value} = props.input;

    const onChange = props.onChange || props.input.onChange;
    const onBlur = props.onBlur || props.input.onBlur;
    const onFocus = props.onFocus || props.input.onFocus;

    const validate = getValidationState(meta);

    const isSideButtonsShowing = showSideButtons;

    const {currentDateFormat} = getCurrentDateSettings();

    const dayTransformer = (action: 'increase' | 'decrease'): void => {
        const {originalDate} = getCurrentDate();
        const manipulating = changeDate(
            value || originalDate,
            {operation: action, value: 1, valueType: 'days'},
            {fromTimezone: 'local'},
        ).jsDate;
        return onClickSideButtons(manipulating);
    };

    const addDay = () => dayTransformer('increase');

    const subtractDay = () => dayTransformer('decrease');

    const handleKeyDown = (event) => {
        if (!isEditableFromKeyBoard || (event.repeat && event.key === 'Enter')) {
            event.preventDefault();
        }
    };

    const mask = generateDateTimeMask(dateFormat || currentDateFormat);

    const inputComponent = (
        <MaskedInput
            placeholder={placeHolder}
            autoComplete="off"
            className="form-control"
            // @ts-ignore
            value={props.value}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onKeyDown={handleKeyDown}
            disabled={disabled}
            guide={true}
            mask={mask}
            ref={ref}
        />
    );

    if (formGroup) {
        return (
            <FormGroup
                className={classNames(
                    {'has-feedback has-error': !validate},
                    {'custom-input-with-datepicker': redesign},
                )}
            >
                {label && <FormLabel>{label}</FormLabel>}
                <OverlayTriggerCustom inputMeta={meta} placement="bottom">
                    <div className={classNames(isSideButtonsShowing && 'react-datepicker-with-sidebuttons')}>
                        {isSideButtonsShowing && (
                            <div
                                onClick={subtractDay}
                                className={classNames('react-datepicker-decrease', {disabled})}
                            />
                        )}
                        {inputComponent}

                        {!isSideButtonsShowing && <i className="fa fa-calendar-o datepicker-icon" />}
                        {isSideButtonsShowing && (
                            <div onClick={addDay} className={classNames('react-datepicker-increase', {disabled})} />
                        )}
                    </div>
                </OverlayTriggerCustom>
                <Form.Control.Feedback type="invalid" className={!validate ? 'error' : ''}>
                    <i className="fa fa-exclamation-circle" />
                </Form.Control.Feedback>
            </FormGroup>
        );
    }

    return (
        <div className="datepicker-no-form-group">
            {inputComponent}
            <i className="fa fa-calendar-o datepicker-icon" />
        </div>
    );
};

export default React.forwardRef(CustomInputWrapper);
