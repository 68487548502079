import isUndefined from 'lodash/isUndefined';
import mapValues from 'lodash/mapValues';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';

import {TrucksNearbySearchFormValues} from 'pages/TrucksNearby/types/formTypes';
import {ListState} from 'pages/TrucksNearby/redux/reducers/list';

import {getTypeFieldNameFactory} from 'utils/typeScript';
import {convertToInches} from 'utils/lengthConverter';
import {convertToLbs} from 'utils/weightConverter';
import countries from 'utils/data/countries';

import {PlaceAddress} from 'types/Address';

const getName = getTypeFieldNameFactory<TrucksNearbySearchFormValues>();

export const buildCityLineFromAddress = (address?: PlaceAddress['address'] | null) => {
    if (!address) {
        return null;
    }

    const countriesMap = {
        [countries.US.name.toLowerCase()]: 'US',
        [countries.CA.name.toLowerCase()]: 'CA',
        [countries.MX.name.toLowerCase()]: 'MX',
    };

    const {city, state, zip, country} = address;

    const formattedCountry = countriesMap[country] || country;
    const formattedZip = zip || 'unknown zip code';

    return `${city}, ${state}, ${formattedZip}, ${formattedCountry}`;
};

const transformDimensionsDataToSearchParams = (params: {formData: Partial<TrucksNearbySearchFormValues>}) => {
    const {formData} = params;

    const dims = {
        dimLength: formData?.dimLength,
        dimHeight: formData?.dimHeight,
        dimWidth: formData?.dimWidth,
    };

    return mapValues(dims, (value) => convertToInches(formData?.dimsUnit, Number(value)));
};

const transformPayloadDataToSearchParams = (params: {formData: Partial<TrucksNearbySearchFormValues>}) => {
    const {formData} = params;

    return convertToLbs(formData?.weightsUnit, Number(formData?.payload));
};

const transformGrossWeightDataToSearchParams = (params: {formData: Partial<TrucksNearbySearchFormValues>}) => {
    const {formData} = params;

    return convertToLbs(formData?.weightsUnit, Number(formData?.gross));
};

export const handleReducerBySetSearchParams = (params: {
    state: ListState;
    formData: Partial<TrucksNearbySearchFormValues>;
}) => {
    const {state, formData} = params;

    const destinationPoint = buildCityLineFromAddress(state?.locationPoints?.destination?.address);
    const originPoint = buildCityLineFromAddress(state?.locationPoints?.origin?.address);

    const grossWeight = transformGrossWeightDataToSearchParams({formData});
    const dimensions = transformDimensionsDataToSearchParams({formData});
    const payload = transformPayloadDataToSearchParams({formData});

    const searchParams = {
        ...formData,

        destinationPoint,
        originPoint,
        grossWeight,
        payload,

        ...dimensions,
    };

    // We create new object to make a certain order to display search result
    const orderedSearchParams = pick(
        searchParams,
        getName('originPoint'),
        getName('radius'),
        getName('destinationPoint'),
        getName('isReefer'),
        getName('type'),
        getName('trailerType'),
        getName('isTeam'),
        getName('statusDateFrom'),
        getName('statusDateTo'),
        getName('status'),
        getName('isReserved'),
        getName('reservedBy'),
        getName('dimsUnit'),
        getName('dimLength'),
        getName('dimWidth'),
        getName('dimHeight'),
        getName('weightsUnit'),
        getName('payload'),
        getName('gross'),
        getName('yearFrom'),
        getName('equipment'),
        getName('owner'),
        getName('driver'),
        getName('citizenship'),
        getName('doorType'),
        getName('crossBorder'),
        getName('certificates'),
        getName('withCompanySigns'),
        getName('preferredLoads'),
    );

    return {
        ...state,
        searchParams: omitBy(orderedSearchParams, (value) => isNull(value) || isUndefined(value)),
    };
};
