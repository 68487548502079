import React from 'react';
import trim from 'lodash/trim';
import isString from 'lodash/isString';

import TooltipOverlayTrigger from './components/TooltipOverlayTrigger';
import TooltipOverlay from './components/TooltipOverlay';
import {TooltipPlacement} from './types';

import styles from './styles.module.scss';

type OwnProps = {
    tooltipContent?: JSX.Element | string;
    tooltipPlacement?: TooltipPlacement;
    isTurnOffTooltip?: boolean;
    isSelectionAllow?: boolean;
};

const TooltipBootstrap: React.FC<OwnProps> = (props) => {
    const {tooltipPlacement = 'top', tooltipContent, isTurnOffTooltip, isSelectionAllow, children} = props;

    if (isTurnOffTooltip || (isString(tooltipContent) && !trim(tooltipContent))) {
        return <>{children}</>;
    }

    if (isSelectionAllow) {
        return (
            <TooltipOverlay
                tooltipPlacement={tooltipPlacement}
                tooltipClassName={styles.tooltip}
                tooltipContent={tooltipContent}
            >
                {children}
            </TooltipOverlay>
        );
    }

    return (
        <TooltipOverlayTrigger
            tooltipPlacement={tooltipPlacement}
            tooltipClassName={styles.tooltip}
            tooltipContent={tooltipContent}
        >
            {children}
        </TooltipOverlayTrigger>
    );
};

export default TooltipBootstrap;
