import {useSelector} from 'react-redux';

import * as registrySelectors from 'store/reducers/registry/selectors';

type UseRegistryState = {
    truckAdditionalEquipment: ReturnType<typeof registrySelectors.getTruckAdditionalEquipment>;
    truckBasicEquipment: ReturnType<typeof registrySelectors.getTruckBasicEquipment>;
    networksOptions: ReturnType<typeof registrySelectors.selectNetworksOptions>;
    networksBenefits: ReturnType<typeof registrySelectors.getNetworksBenefits>;
    currency: ReturnType<typeof registrySelectors.getCurrency>;
};

const useRegistryState = (): UseRegistryState => ({
    truckAdditionalEquipment: useSelector(registrySelectors.getTruckAdditionalEquipment),
    truckBasicEquipment: useSelector(registrySelectors.getTruckBasicEquipment),
    networksOptions: useSelector(registrySelectors.selectNetworksOptions),
    networksBenefits: useSelector(registrySelectors.getNetworksBenefits),
    currency: useSelector(registrySelectors.getCurrency),
});

export default useRegistryState;
