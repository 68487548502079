import React from 'react';
import DatePicker, {CalendarContainer} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';

import {getCurrentDateSettings} from 'utils/dateTime';

import CustomInputWrapper from './components/CustomInputWrapper';
import {convertFromJSDate, getMinDate, getMaxDate, getSelectedDate} from './utils';
import {TIME_INTERVALS, YEAR_DROPDOWN_NUMBER} from './constants';
import './style.scss';

const InputWithDatePicker = (props): JSX.Element | null => {
    const {
        marginLeft,
        formGroup = true,
        minDate,
        maxDate,
        yearDropdownItemNumber,
        timeIntervals,
        disabled,
        isEditableFromKeyBoard = true,
        redesign,
        placeholder,
        withHoursMinSec,
        dateFormat,
        ...rest
    } = props;

    const intervals = timeIntervals || TIME_INTERVALS;
    const yearItemNumber = yearDropdownItemNumber || YEAR_DROPDOWN_NUMBER;

    const selectedDate = getSelectedDate(props?.input?.value);
    const {currentDateFormat} = getCurrentDateSettings();
    const minVisibleDate = getMinDate(minDate);
    const maxVisibleDate = getMaxDate(maxDate);

    const handleCalendarChange = (date: Date) => {
        if (!date) {
            props.input.onChange('');
            return;
        }
        const dateString = convertFromJSDate(date, {withSecondsFormat: withHoursMinSec});
        props.input.onChange(dateString);
    };

    const customDatePicker = ({className, children}) => {
        return (
            <div style={{marginLeft}}>
                <div className="react-datepicker__triangle" />
                <CalendarContainer className={className}>{children}</CalendarContainer>
            </div>
        );
    };
    const customInput = (
        <CustomInputWrapper
            label={props.label}
            meta={props.meta}
            placeHolder={placeholder}
            input={props.input}
            formGroup={formGroup}
            showSideButtons={props.showSideButtons}
            isEditableFromKeyBoard={isEditableFromKeyBoard}
            redesign={redesign}
            onClickSideButtons={handleCalendarChange}
            dateFormat={dateFormat}
        />
    );

    return (
        <DatePicker
            calendarContainer={customDatePicker}
            dateFormat={dateFormat || currentDateFormat}
            calendarClassName="datepicker-component"
            customInput={customInput}
            timeIntervals={intervals}
            showMonthDropdown
            showYearDropdown
            disabledKeyboardNavigation={false}
            yearDropdownItemNumber={yearItemNumber}
            scrollableYearDropdown
            minDate={minVisibleDate}
            maxDate={maxVisibleDate}
            selected={selectedDate}
            onChange={handleCalendarChange}
            dropdownMode="select"
            disabled={disabled}
            shouldCloseOnSelect={true}
            {...rest}
        />
    );
};

export default InputWithDatePicker;
