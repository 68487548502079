import React, {useState} from 'react';
import classNames from 'classnames';
import {FormLabel, FormGroup} from 'react-bootstrap';

import {handleKeyDown} from 'utils/form/handleKeyDown';

import OverlayTriggerCustom from 'components/ui/Form/OverlayTriggerCustom';

import getValidationState from '../getValidationState';
import ObjectValuesSelect from './components/ObjectValuesSelect';
import MultiValuesSelect from './components/MultiValuesSelect';
import SingleValuesSelect from './components/SingleValuesSelect';

import styles from './styles.module.scss';

export interface OwnProps {
    meta?: any;
    input: any;
    label?: string | JSX.Element;
    formGroup?: boolean;
    isDisabled?: boolean;
    isCreatable?: boolean;
    isClearable?: boolean;
    isMulti?: boolean;
    isLoading?: boolean;
    placeholder?: string;
    valueAsObject?: boolean;
    valueAsArray?: boolean;
    redesign?: boolean;
    isLargeSize?: boolean;
    withLongLabel?: boolean;
    menuWidth?: number;
    data: any;
    delimiter?: string;
    type?: string;
    menuPlacement?: string;
}

const DEFAULT_MENU_WIDTH = 450;

const Select: React.FC<OwnProps> = (props) => {
    const {
        meta,
        input: {onBlur = () => {}, onFocus},
        data,
        label,
        placeholder = '',
        redesign,
        menuWidth = DEFAULT_MENU_WIDTH,
        formGroup = true,
        isDisabled = false,
        isLoading = false,
        withLongLabel = false,
        valueAsObject = false,
        valueAsArray = false,
        isCreatable = false,
        isClearable = false,
        isLargeSize = false,
        isMulti,
    } = props;

    const [open, setOpen] = useState(false);
    const isValidationSuccess = getValidationState(meta);

    const getSelectComponentClassName = (): string =>
        classNames('select', styles.select_component, {
            [styles.redesign]: redesign,
            [styles.large_size]: isLargeSize,
            [styles.error]: !isValidationSuccess,
        });

    const getSelectComponent = () => {
        if (isMulti && valueAsObject) {
            return MultiValuesSelect;
        }
        if (valueAsObject) {
            return ObjectValuesSelect;
        }
        if (isMulti) {
            return MultiValuesSelect;
        }
        return SingleValuesSelect;
    };

    const selectProps = {
        ...props,
        className: getSelectComponentClassName(),
        classNamePrefix: styles.select_component,
        options: data,
        // "select" does not work on android https://github.com/JedWatson/react-select/issues/2692#issuecomment-488541149
        onBlur: () => onBlur(),
        onKeyDown: handleKeyDown,
        onFocus,
        placeholder: isLoading ? 'loading...' : placeholder,
        // https://github.com/JedWatson/react-select/issues/3128
        ignoreAccents: false,
        // menuIsOpen: true,
        isDisabled,
        valueAsArray,
        isCreatable,
        isClearable,
        isLargeSize,
    };

    const additionalPropsWithLongLabel = {
        onMenuOpen: () => setOpen(true),
        onMenuClose: () => setOpen(false),
        getOptionLabel: (option) => (open ? option.longLabel : option.label),
        styles: {menu: (s) => ({...s, width: menuWidth})},
    };

    const transformedSelectProps = withLongLabel ? {...selectProps, ...additionalPropsWithLongLabel} : selectProps;

    const SelectComponent = getSelectComponent();

    if (formGroup) {
        return (
            <FormGroup
                controlId="formControlsSelect"
                className={`${isValidationSuccess ? '' : 'has-error'} ${isDisabled ? 'has-disabled' : ''}`}
            >
                {label && <FormLabel>{label}</FormLabel>}
                <OverlayTriggerCustom inputMeta={meta} placement="bottom">
                    {/* @ts-ignore */}
                    <SelectComponent {...transformedSelectProps} />
                </OverlayTriggerCustom>
            </FormGroup>
        );
    }

    // @ts-ignore
    return <SelectComponent {...selectProps} />;
};

export default Select;
