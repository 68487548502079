import React from 'react';

import ReactWindowWrapper from 'components/ui/ReactWindowWrapper';
import Select from 'components/ui/Form/Select';

const OptimizationWrapper = ({itemHeight, ...props}): JSX.Element => {
    const MenuListForSelect = (menuListProps) => {
        return <ReactWindowWrapper itemHeight={itemHeight} {...menuListProps} />;
    };
    return (
        <Select
            {...props}
            // @ts-ignore
            noOptionsMessage={() => 'no options'}
            components={props.data.length > 0 ? {MenuList: MenuListForSelect} : null}
        />
    );
};

export default OptimizationWrapper;
