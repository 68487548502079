import React, {useState, useMemo, useCallback, Fragment, ReactNodeArray} from 'react';
import {useDispatch} from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import flattenGroupedArrays from 'utils/flattenGroupedArrays';

import imageGalleryActions from 'store/reducers/gallery/actionCreators';

import FilePreview from 'components/ui/Files/FileUpload/components/FilePreview';
import SearchInput from 'components/ui/Files/FileUpload/components/FilesList/SearchInput';
import FileSection from 'components/ui/Files/FileUpload/components/FilesList/FileSection';

import {NormalizedFile, NormalizedFileWithoutExtraData, NormalizedFileData} from '../../types';

import {getNextGroupStartingIndex, checkIfFileNameMatchesFilter} from './utils';
import styles from './fileList.module.scss';

interface OwnProps {
    filesData: NormalizedFileData;
    title?: string;
    gridView?: boolean;
    groupByKey?: boolean;
    viewButton?: boolean;
    viewInGalleryButton?: boolean;
    warnOnRemove?: boolean;
}

const FilesList = (props: OwnProps): JSX.Element => {
    const {filesData, title, groupByKey, viewButton, viewInGalleryButton, warnOnRemove} = props;
    const [gridView, setGridView] = useState(props.gridView);
    const [filter, setFilter] = useState<any>();
    const dispatch = useDispatch();

    const flattenedFilesData = useMemo(
        function (): (NormalizedFile | NormalizedFileWithoutExtraData)[] {
            return flattenGroupedArrays<NormalizedFile | NormalizedFileWithoutExtraData>(filesData);
        },
        [filesData],
    );

    const toggleView = () => setGridView((prevState) => !prevState);

    const handleOpenGallery = (index: number) => {
        dispatch(imageGalleryActions.openGallery({files: flattenedFilesData, currentItemIndex: index}));
    };

    const handleSearch = useCallback(function (event: React.FormEvent<HTMLInputElement>): void {
        setFilter(event.currentTarget.value);
    }, []);

    function renderFilePreviews(
        files: (NormalizedFile | NormalizedFileWithoutExtraData)[],
        startingGroupIndex?: number,
    ): ReactNodeArray {
        return files.map((file: NormalizedFile | NormalizedFileWithoutExtraData, fileIndex: number):
            | JSX.Element
            | undefined => {
            if (!checkIfFileNameMatchesFilter(file.name, filter)) {
                return;
            }
            const galleryIndex: number = startingGroupIndex ? startingGroupIndex + fileIndex : fileIndex;
            return (
                <FilePreview
                    key={fileIndex}
                    file={file}
                    grid={gridView}
                    handleRemove={file.handleRemove}
                    viewInGallery={() => handleOpenGallery(galleryIndex)}
                    viewButton={viewButton}
                    viewInGalleryButton={viewInGalleryButton}
                    warnOnRemove={warnOnRemove}
                />
            );
        });
    }
    function renderFileSections(): ReactNodeArray {
        return Object.entries(filesData).map(
            ([key, files]: [string, NormalizedFile[]], groupIndex: number, groupedFiles): JSX.Element | null => {
                const nextGroupStartingIndex = getNextGroupStartingIndex<NormalizedFile>(groupedFiles, groupIndex);
                if (!files.length) {
                    return null;
                }
                return (
                    <div key={key}>
                        <FileSection title={key} fileCount={files.length} />
                        <div className="file-items-list">{renderFilePreviews(files, nextGroupStartingIndex)}</div>
                    </div>
                );
            },
        );
    }
    function renderFiles(): ReactNodeArray {
        if (isEmpty(filesData)) {
            return [];
        }
        if (Array.isArray(filesData) && !groupByKey) {
            return renderFilePreviews(filesData);
        }
        return renderFileSections();
    }
    const files = useMemo(renderFiles, [filesData, gridView, filter]);
    const viewIconClass = gridView ? 'action-icon-list' : 'action-icon-grid';
    const fileCount = flattenedFilesData.length;
    const viewFiles = Boolean(!isEmpty(files) && fileCount);

    return (
        <>
            <div className={styles.heading}>
                {title ? (
                    <div className="files-heading">
                        <i className="fa fa-files-o" aria-hidden="true" />
                        <div className="f-heading-title">
                            <p className="ellips-text" title={title}>
                                {title}
                            </p>
                            <div>
                                HAS
                                <strong className="ml5 mr5">{fileCount}</strong> FILE
                                {fileCount === 1 ? '' : 'S'}
                            </div>
                        </div>
                        <div className="control-outer">
                            <SearchInput handleChange={handleSearch} disabled={fileCount === 0} />
                            <button
                                className={`button button-sm action-icon ${viewIconClass}`}
                                type="button"
                                onClick={toggleView}
                                aria-label="toggle view"
                                disabled={fileCount === 0}
                            />
                        </div>
                    </div>
                ) : null}
            </div>

            <div className={styles.items}>
                <div
                    className={`view-files ${gridView ? 'grid-view' : 'list-view view-files_scroll'} ${
                        groupByKey && 'group-files'
                    }`}
                >
                    {viewFiles ? files : <div className="empty-list">There are no files in the {title}</div>}
                </div>
            </div>
        </>
    );
};

export default FilesList;
