import toNumber from 'lodash/toNumber';
import sumBy from 'lodash/sumBy';

import {getTravelOrderStopByID} from 'core/entities/TravelOrder/modules/travelOrderStops';
import TravelOrderStop from 'core/entities/TravelOrder/types/TravelOrderStop';
import {StopInfoParams} from 'core/entities/TravelOrder/types/StopInfoParams';

const getTotalNumberOfPieces = (stop?: TravelOrderStop): number =>
    sumBy(stop?.bols || [], (bol) => toNumber(bol?.pieces || 0));
const getTotalNumberOfWeight = (stop?: TravelOrderStop): number =>
    sumBy(stop?.bols || [], (bol) => toNumber(bol?.weight.amount || 0));
const getWeightUnit = (stop?: TravelOrderStop): string | undefined => {
    const [firstBoL] = stop?.bols || [];
    return firstBoL?.weight.unit;
};
const getBOLs = (stop?: TravelOrderStop): TravelOrderStop['bols'] => stop?.bols || [];

export type TOStopBOLsInfo = {
    totalNumberOfPieces: ReturnType<typeof getTotalNumberOfPieces>;
    totalNumberOfWeight: ReturnType<typeof getTotalNumberOfWeight>;
    weightUnit: ReturnType<typeof getWeightUnit>;
    list: ReturnType<typeof getBOLs>;
};

export const getTravelOrderStopBOLsInfo = (stop?: TravelOrderStop): TOStopBOLsInfo => ({
    totalNumberOfPieces: getTotalNumberOfPieces(stop),
    totalNumberOfWeight: getTotalNumberOfWeight(stop),
    weightUnit: getWeightUnit(stop),
    list: getBOLs(stop),
});

export const getTravelOrderStopBOLsInfoByID = (params: StopInfoParams): TOStopBOLsInfo =>
    getTravelOrderStopBOLsInfo(getTravelOrderStopByID(params));
