import includes from 'lodash/includes';
import isNumber from 'lodash/isNumber';
import {change, initialize, reset} from 'redux-form';

import * as appActions from 'store/actions';
import {getCurrentRegion} from 'store/reducers/appSettings/selectors';

import Truck from 'core/entities/Truck/types';
import * as requests from 'core/gateways/TrucksNearbyApiGateway/requests';

import {awaySortValues, SEARCH_FORM_NAME, SortBy, TNB_TABS} from 'pages/TrucksNearby/constants';
import * as types from 'pages/TrucksNearby/redux/actionTypes';
import {transformSearchDataToRequestBody} from 'pages/TrucksNearby/redux/mappers/searchForm';
import * as selectors from 'pages/TrucksNearby/redux/selectors';
import {TrucksNearbySearchFormValues} from 'pages/TrucksNearby/types/formTypes';
import {getDefaultSearchParams} from 'pages/TrucksNearby/utils';

import transformApiPaginationToClient from 'utils/pagination';
import {getTypeFieldNameFactory} from 'utils/typeScript';

import {PlaceAddress} from 'types/Address';
import Pagination from 'types/Pagination';

const getName = getTypeFieldNameFactory<TrucksNearbySearchFormValues>();

export const listActionCreators = {
    setPagination: (payload: {pagination: Pagination}) => ({type: types.PAGINATION_RECEIVED, payload} as const),
    setSortBy: (payload: {sortBy: SortBy}) => ({type: types.LIST_SORT_BY_RECEIVED, payload} as const),
    setTab: (payload: {tab: string}) => ({type: types.LIST_TAB_RECEIVED, payload} as const),
    setExpandedIDs: (payload: {expandedIDs: number[]}) => ({type: types.EXPANDED_IDS_RECEIVED, payload} as const),
    setTrucks: (payload: {items: Truck[]}) => ({type: types.TRUCKS_RECEIVED, payload} as const),
    setLocationPoints: (payload: {origin: PlaceAddress | null; destination: PlaceAddress | null}) =>
        ({type: types.LOCATION_POINTS_RECEIVED, payload} as const),
    setSearchParams: (payload: {searchParams: Partial<TrucksNearbySearchFormValues>}) =>
        ({type: types.SEARCH_PARAMS_RECEIVED, payload} as const),
    setExternalSearchParams: (payload: {externalSearchParams: Partial<TrucksNearbySearchFormValues> | null}) =>
        ({type: types.EXTERNAL_SEARCH_PARAMS_RECEIVED, payload} as const),
    clearExternalSearchParams: () => ({type: types.EXTERNAL_SEARCH_PARAMS_CLEARED} as const),
    clearTNBListState: () => ({type: types.LIST_STATE_CLEARED} as const),
};

const getTrucks = (params: {requestBody}) => async (dispatch, getState) => {
    const {requestBody} = params;

    const state = getState();

    const listTab = selectors.getListTab(state);

    const getTrucksRequest = listTab === TNB_TABS.OWN ? requests.getOwnTrucks : requests.getPartnersTrucks;

    try {
        dispatch(appActions.showLoader());

        const {data} = await getTrucksRequest({requestPayload: requestBody});

        return {...data, pagination: transformApiPaginationToClient(data.pagination)};
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

const setLocationPoints = (params: {searchResult}) => (dispatch) => {
    const {searchResult} = params;

    const points = {origin: searchResult?.origin_location, destination: searchResult?.destination_location};

    dispatch(listActionCreators.setLocationPoints(points));
};

const updateLocationPointsFormFields = () => (dispatch, getState) => {
    const state = getState();

    const searchParams = selectors.getSearchParams(state);

    if (searchParams?.originPoint) {
        dispatch(change(SEARCH_FORM_NAME, getName('originPoint'), searchParams?.originPoint));
    }

    if (searchParams?.destinationPoint) {
        dispatch(change(SEARCH_FORM_NAME, getName('destinationPoint'), searchParams?.destinationPoint));
    }
};

export const getTrucksByFilters = (params: {filters: Partial<TrucksNearbySearchFormValues>}) => async (
    dispatch,
    getState,
) => {
    const {filters: followingFilters} = params;

    const state = getState();

    const pagination = selectors.getListPagination(state);
    const sorting = selectors.getSorting(state);
    const sortBy = followingFilters.destinationPoint ? sorting.sortBy : awaySortValues.origin;

    const transformedSearchData = transformSearchDataToRequestBody({formData: followingFilters});

    const requestBody = {
        ...transformedSearchData,
        perPage: pagination.perPage,
        tnbSortBy: sortBy,
        page: 1,
    };

    const searchResult = await dispatch(getTrucks({requestBody}));

    if (!searchResult) {
        return null;
    }

    dispatch(setLocationPoints({searchResult}));
    dispatch(listActionCreators.setSortBy({sortBy}));
    dispatch(listActionCreators.setTrucks({items: searchResult.items}));
    dispatch(listActionCreators.setSearchParams({searchParams: followingFilters}));
    dispatch(listActionCreators.setPagination({pagination: searchResult.pagination}));
    dispatch(updateLocationPointsFormFields());
};

export const getTrucksByPage = (params: {page: number}) => async (dispatch, getState) => {
    const {page: followingPage} = params;
    const state = getState();

    const pagination = selectors.getListPagination(state);
    const searchParams = selectors.getSearchParams(state);
    const sorting = selectors.getSorting(state);

    const transformedSearchData = transformSearchDataToRequestBody({formData: searchParams});

    const requestBody = {
        ...transformedSearchData,
        perPage: pagination.perPage,
        tnbSortBy: sorting.sortBy,
        page: followingPage,
    };

    const searchResult = await dispatch(getTrucks({requestBody}));

    if (!searchResult) {
        return null;
    }

    dispatch(setLocationPoints({searchResult}));
    dispatch(listActionCreators.setTrucks({items: searchResult.items}));
    dispatch(listActionCreators.setPagination({pagination: searchResult.pagination}));
    dispatch(updateLocationPointsFormFields());
};

export const getTrucksByPerPage = (params: {perPage: number}) => async (dispatch, getState) => {
    const {perPage: followingPerPage} = params;
    const state = getState();

    const searchParams = selectors.getSearchParams(state);
    const sorting = selectors.getSorting(state);

    const transformedSearchData = transformSearchDataToRequestBody({formData: searchParams});

    const requestBody = {
        ...transformedSearchData,
        tnbSortBy: sorting.sortBy,
        perPage: followingPerPage,
        page: 1,
    };

    const searchResult = await dispatch(getTrucks({requestBody}));

    if (!searchResult) {
        return null;
    }

    dispatch(setLocationPoints({searchResult}));
    dispatch(listActionCreators.setTrucks({items: searchResult.items}));
    dispatch(listActionCreators.setPagination({pagination: searchResult.pagination}));
    dispatch(updateLocationPointsFormFields());
};

export const getTrucksBySortBy = (params: {sortBy: SortBy}) => async (dispatch, getState) => {
    const {sortBy: followingSortBy} = params;
    const state = getState();

    const searchParams = selectors.getSearchParams(state);
    const pagination = selectors.getListPagination(state);

    const transformedSearchData = transformSearchDataToRequestBody({formData: searchParams});

    const requestBody = {
        ...transformedSearchData,
        tnbSortBy: followingSortBy,
        perPage: pagination.perPage,
        page: 1,
    };

    const searchResult = await dispatch(getTrucks({requestBody}));

    if (!searchResult) {
        return null;
    }

    dispatch(setLocationPoints({searchResult}));
    dispatch(listActionCreators.setTrucks({items: searchResult.items}));
    dispatch(listActionCreators.setPagination({pagination: searchResult.pagination}));
    dispatch(listActionCreators.setSortBy({sortBy: followingSortBy}));
    dispatch(updateLocationPointsFormFields());
};

export const toggleTruckRow = (params: {truckID: number}) => (dispatch, getState) => {
    const {truckID} = params;
    const state = getState();

    if (!truckID || !isNumber(truckID)) {
        return null;
    }

    const expandedIDs = selectors.getExpandedIDs(state);

    const isFollowingRowExpanded = includes(expandedIDs, truckID);

    const expandedIDsWithNewID = [...expandedIDs, truckID];
    const expandedIDsWithOutFollowingID = expandedIDs.filter((id) => id !== truckID);

    const newExpandedIDs = isFollowingRowExpanded ? expandedIDsWithOutFollowingID : expandedIDsWithNewID;

    dispatch(listActionCreators.setExpandedIDs({expandedIDs: newExpandedIDs}));
};

export const toggleAllTruckRows = () => (dispatch, getState) => {
    const state = getState();

    const expandedIDs = selectors.getExpandedIDs(state);
    const trucks = selectors.getTrucks(state);

    const newExpandedIDs = !expandedIDs.length ? trucks.map((truck) => truck.id) : [];

    dispatch(listActionCreators.setExpandedIDs({expandedIDs: newExpandedIDs}));
};

export const setListTab = (params: {tab: string}) => (dispatch) => {
    const {tab} = params;

    dispatch(listActionCreators.setTab({tab}));
};

export const resetSearchForm = () => (dispatch, getState) => {
    const state = getState();
    const currentRegion = getCurrentRegion(state);

    const externalSearchParams = selectors.getExternalSearchParams(state);

    const defaultSearchParams = getDefaultSearchParams(currentRegion);

    if (externalSearchParams) {
        dispatch(listActionCreators.clearExternalSearchParams());
    }

    dispatch(reset(SEARCH_FORM_NAME));

    dispatch(initialize(SEARCH_FORM_NAME, defaultSearchParams));
};
