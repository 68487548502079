import React from 'react';
import {WrappedFieldProps} from 'redux-form';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';

import SelectOption from 'types/SelectOption';

import {flattenGroupedOptions} from '../../utils';

interface OwnProps extends WrappedFieldProps {
    isCreatable?: boolean;
    data: SelectOption[];
}

const getDefaultValue = (
    {input: {value: inputValue}, isCreatable}: OwnProps,
    options: SelectOption[],
): SelectOption | null => {
    const listOptions: any = flattenGroupedOptions(options);
    const defOption = listOptions.find((option) => option.value === inputValue);
    // for case when user enter manual value
    if (inputValue && isCreatable && !defOption) {
        return {label: inputValue, value: inputValue};
    }
    // important for case when default option doesnt exists return null cause other falsy values like undefined wont be work
    return defOption || null;
};

const getSelectComponent = (props: OwnProps) => {
    return props.isCreatable ? CreatableSelect : ReactSelect;
};

function onChangeHandler(option, props: OwnProps): void {
    if (!option) {
        props.input.onChange('');

        return;
    }

    props.input.onChange(option.value);
}

const SingleValuesSelect: React.FC<OwnProps> = (props) => {
    const {data} = props;
    const defaultValue = getDefaultValue(props, data);
    // @ts-ignore
    const selectProps = {
        ...props,
        options: data,
        value: defaultValue,
        onChange: (option) => onChangeHandler(option, props),
    };
    // @ts-ignore
    const SelectComponent = getSelectComponent(props);

    // @ts-ignore
    return <SelectComponent {...selectProps} />;
};

export default SingleValuesSelect;
