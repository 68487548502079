import compact from 'lodash/compact';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import {change, reset} from 'redux-form';

import * as appActions from 'store/actions';
import {handleError} from 'store/actions/common';

import Load from 'core/entities/Load/types';
import loadApiGateway from 'core/gateways/LoadApiGateway';
import {
    addLoadNotes as addLoadNotesUseCase,
    createLoad as createLoadUseCase,
    getLoad as getLoadUseCase,
    updateLoad as updateLoadUseCase,
} from 'core/useCases/Load';
import {UploadedLoadFiles} from 'core/useCases/Load/types';
import {transformEmailsFromApi} from 'core/useCases/Load/utils';

import {FILE_IDS_TO_BE_REMOVED_KEY} from 'components/ui/Files/FileUpload/constants';

import {CREATE_LOAD_FORM_NAME, FORM_BOOKING_INFO_STEP, MODAL_NAMES} from 'pages/Loads/constants/loadConstants';
import {LoadFormValues} from 'pages/Loads/types/formTypes';
import {getIsUpdatesSentToAgentEmail, getIsUpdatesSentToDispatcherEmail} from 'pages/Loads/utils/initialValues';

import openNewWindow from 'utils/openNewWindow';

import {FileNew} from 'types/File';

import {actionCreators} from '../../actionCreators';
import {actionCreators as actionCreatorsForModal} from '../../actionCreators/modal';
import {getLoad as getLoadSelector, getLoadCopyFields} from '../../selectors/common';
import * as modalActions from '../modalActions';

export function clearFormFields() {
    return function (dispatch, getState) {
        const {
            loads: {
                modal: {data: modalData},
            },
        } = getState();

        dispatch(reset(modalData.formName));
        dispatch(modalActions.closeModal());
        dispatch(actionCreators.changeCreateFormStep(FORM_BOOKING_INFO_STEP));
    };
}

const getUploadedFiles = (updatedLoad): UploadedLoadFiles[] => {
    const {
        files: {
            load: {
                new_rc: listUploadedRcFiles,
                new_bol: listUploadedBolFiles,
                new_pod: listUploadedPodFiles,
                new_files: listUploadedGeneralFiles,
                new_extra: listUploadedExtraFiles,
            },
        },
    } = updatedLoad;

    const allUploadedFiles = [
        {type: 'rc', items: listUploadedRcFiles || []},
        {type: 'bol', items: listUploadedBolFiles || []},
        {type: 'pod', items: listUploadedPodFiles || []},
        {type: 'files', items: listUploadedGeneralFiles || []},
        {type: 'extra', items: listUploadedExtraFiles || []},
    ].filter((data) => data.items.length > 0) as UploadedLoadFiles[];

    return allUploadedFiles;
};

const getDeletedFiles = (updatedLoad): number[] => {
    return updatedLoad?.files?.load[FILE_IDS_TO_BE_REMOVED_KEY] || [];
};

const getDeletedStopsIds = (changedLoad: Load, currentLoad: Load): string[] => {
    const changedLoadStopsIds = compact(changedLoad.stops.map((item) => item.id));
    const currentLoadStopsIds = compact(currentLoad.stops.map((item) => item.id));

    return difference(currentLoadStopsIds, changedLoadStopsIds);
};

export function getLoad(loadNumber: Load['number']) {
    return async function (dispatch) {
        if (!loadNumber) {
            return null;
        }
        dispatch(actionCreators.currentLoadStartLoading());
        dispatch(appActions.showLoader());
        try {
            const {load} = await getLoadUseCase({loadNumber});
            dispatch(actionCreators.fetchLoad({load}));
            dispatch(actionCreators.currentLoadStopLoading());
        } catch (error) {
            dispatch(handleError(error));
        }
        dispatch(appActions.hideLoader());
    };
}

export const createLoad = (formFields: LoadFormValues) => async (dispatch) => {
    try {
        dispatch(appActions.showLoader());

        const {createdLoad, loadNumber, hasAccessToView} = await createLoadUseCase({
            loadData: omit(formFields, ['files']) as LoadFormValues,
            addedFiles: getUploadedFiles(formFields),
        });

        const successModalAction = actionCreatorsForModal.showModal({
            modalName: MODAL_NAMES.successfulCreateModal,
            modalData: {loadNumber, hasAccessToView, load: createdLoad},
        });

        dispatch(actionCreators.createLoad());
        dispatch(successModalAction);
    } catch (e) {
        dispatch(appActions.handleError(e));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export const updateLoad = (changedLoad: LoadFormValues) => async (dispatch, getState) => {
    const currentLoad = getLoadSelector(getState()) as Load;
    const deletedLoadStopsIds = getDeletedStopsIds(changedLoad, currentLoad);
    const formattedChangedLoad = omit(changedLoad, ['files']) as LoadFormValues;

    try {
        dispatch(appActions.showLoader());

        const {updatedLoad, hasAccessToView, loadNumber} = await updateLoadUseCase({
            deletedFiles: getDeletedFiles(changedLoad),
            addedFiles: getUploadedFiles(changedLoad),
            originalLoad: currentLoad,
            changedLoad: formattedChangedLoad,
            deletedLoadStopsIds,
        });

        const successModalAction = actionCreatorsForModal.showModal({
            modalName: MODAL_NAMES.successfullyUpdatedModal,
            modalData: {load: updatedLoad, hasAccessToView, loadNumber},
        });

        dispatch(successModalAction);
    } catch (e) {
        dispatch(appActions.handleError(e));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export function addLoadNote(newNote: {text: string}, loadNumber: number) {
    return function (dispatch) {
        dispatch(appActions.showLoader());
        addLoadNotesUseCase(loadNumber, {note: newNote.text, isImportant: false})
            .then(({updatedLoad}) => {
                dispatch(actionCreators.fetchLoad({load: updatedLoad}));
            })
            .catch((error) => {
                dispatch(appActions.handleError(error));
            })
            .finally(() => dispatch(appActions.hideLoader()));
    };
}

export function generateLoadBOL(loadNumber: number) {
    return async function (dispatch) {
        dispatch(appActions.showLoader());
        try {
            const pathToGeneratedBOL = await loadApiGateway.generateLoadBol(loadNumber);

            if (pathToGeneratedBOL) {
                openNewWindow({newWindowUrl: pathToGeneratedBOL, newWindowTitle: 'BOL'});
            }
        } catch (e) {
            dispatch(appActions.handleError(e));
        } finally {
            dispatch(appActions.hideLoader());
        }
    };
}

export function generateLoadInvoice(fileFromForm: FileNew[], load: Load) {
    return async function (dispatch) {
        dispatch(appActions.showLoader());

        try {
            const pathToGeneratedPDF = await loadApiGateway.generateInvoice(load.number, fileFromForm);

            if (pathToGeneratedPDF) {
                openNewWindow({newWindowUrl: pathToGeneratedPDF, newWindowTitle: 'INVOICE'});
            }
        } catch (e) {
            dispatch(appActions.handleError(e));
        }

        dispatch(appActions.hideLoader());
    };
}

export function initializeCreateLoadForm() {
    return function (dispatch, getState) {
        const state = getState();
        const loadCopyFields = getLoadCopyFields(state);

        if (!loadCopyFields) {
            return;
        }

        function changeFormField([key, value]) {
            dispatch(change(CREATE_LOAD_FORM_NAME, key, value));
        }

        const load = {
            ...loadCopyFields,
            emailsToUpdate: transformEmailsFromApi(loadCopyFields as Load),
            dispatcher_send_updates: getIsUpdatesSentToDispatcherEmail(loadCopyFields as Load),
            agent_send_updates: getIsUpdatesSentToAgentEmail(loadCopyFields as Load),
        };

        Object.entries(omit(load)).forEach(changeFormField);
    };
}
