import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';

import * as cancelTravelOderActions from 'pages/TravelOrders/redux/actions/travelOrder/cancelTO';
import * as travelOrderCarrierActions from 'pages/TravelOrders/redux/actions/travelOrder/carrier';
import * as dryRunTravelOderActions from 'pages/TravelOrders/redux/actions/travelOrder/dryRun';
import * as brokerActions from 'pages/TravelOrders/redux/actions/travelOrder/broker';
import * as travelOrderActions from 'pages/TravelOrders/redux/actions/travelOrder';

import * as formTypes from 'pages/TravelOrders/types/formTypes';

import Carrier from 'core/entities/Carrier/types';

type TravelOrderActions = {
    cancelNotPlannedTOWithoutPlannedTO: (cancelData: formTypes.CancelNotPlannedTOWithoutPlannedTOFormValues) => void;
    dispatchReDispatchTravelOrder: (dispatchReDispatchData: formTypes.DispatchReDispatchModalFormValues) => void;
    cancelNotPlannedTOWithPlannedTO: (cancelData: formTypes.CancelNotPlannedTOWithPlannedTOFormValues) => void;
    sendTravelOrderEmailAttachments: (attachmentData: formTypes.EmailAttachmentFormValues) => void;
    deliveryStopCheckOut: (checkOutData: formTypes.DeliveryCheckOutModalFormValues) => void;
    addTravelOrderOtherFiles: (data: Partial<formTypes.AddOtherFilesTOFormValues>) => void;
    editDeadheadDetails: (deadheadData: formTypes.EditDeadheadDetailsFormValues) => void;
    pickUpStopCheckOut: (checkOutData: formTypes.PickUpCheckOutModalFormValues) => void;
    confirmDispatchReDispatch: (confirmData: formTypes.ConfirmModalFormValues) => void;
    editDeadheadFiles: (deadheadData: formTypes.EditDeadheadFilesFormValues) => void;
    addProofOfDelivery: (podData: formTypes.ProofOfDeliveryModalFormValues) => void;
    addPODsForTheAllDeliveries: (podData: formTypes.NotSignedPODFormValues) => void;
    confirmDryRun: (confirmDryRunData: formTypes.DryRunConfirmFormValues) => void;
    addBillOfLading: (bolData: formTypes.AddBillOfLadingModalFormValues) => void;
    finishTravelOrder: (finishData: formTypes.FinishModalFormValues) => void;
    setPayAndGoToDispatch: (payData: formTypes.PayModalFormValues) => void;
    editCheckOut: (checkOutData: formTypes.EditCheckOutFormValues) => void;
    editCheckIn: (checkInData: formTypes.EditCheckInFormValues) => void;
    editDryRun: (dryRunData: formTypes.DryRunModalFormValues) => void;
    setDryRun: (dryRunData: formTypes.DryRunModalFormValues) => void;
    stopCheckIn: (checkInData: formTypes.CheckInFormValues) => void;
    removeTravelOrderOtherFileByID: (fileID: number) => void;
    editPODs: (podData: formTypes.EditPODFormValues) => void;
    setPay: (payData: formTypes.PayModalFormValues) => void;
    editBOL: (bolData: formTypes.EditBOLFormValues) => void;
    cancelPlannedTravelOrder: () => void;
    cancelCheckOut: () => void;
    cancelCheckIn: () => void;
    cancelSignPOD: () => void;
    cancelAddBOL: () => void;
    cancelFinish: () => void;
    cancelDryRun: () => void;

    //
    sendEmailToTravelOrderCarrier: (carrier: Carrier | null | undefined) => void;
    runTruckManual: (selectedTravelOrder) => void;
    fetchTravelOrder: (id?: string | number) => void;
    toggleSendThirdApiRequest: () => void;
    redirectTo: (url: string) => void;
    clearState: () => void;
};

export default (): TravelOrderActions => {
    const dispatch = useDispatch();
    const history = useHistory();

    return {
        pickUpStopCheckOut: (checkOutData: formTypes.PickUpCheckOutModalFormValues) =>
            dispatch(travelOrderActions.pickUpStopCheckOut(checkOutData)),
        deliveryStopCheckOut: (checkOutData: formTypes.DeliveryCheckOutModalFormValues) =>
            dispatch(travelOrderActions.deliveryStopCheckOut(checkOutData)),
        toggleSendThirdApiRequest: () => dispatch(brokerActions.toggleIsNeedSendThirdApiRequest()),
        setPay: (payData: formTypes.PayModalFormValues) =>
            dispatch(travelOrderActions.setTravelOrderPay(payData, {withDispatchModal: false})),
        setPayAndGoToDispatch: (payData: formTypes.PayModalFormValues) =>
            dispatch(travelOrderActions.setTravelOrderPay(payData, {withDispatchModal: true})),
        confirmDispatchReDispatch: (confirmData: formTypes.ConfirmModalFormValues) =>
            dispatch(travelOrderActions.confirmDispatchReDispatch(confirmData)),
        dispatchReDispatchTravelOrder: (dispatchReDispatchData: formTypes.DispatchReDispatchModalFormValues) =>
            dispatch(travelOrderActions.dispatchReDispatchTravelOrder(dispatchReDispatchData)),
        runTruckManual: (selectedTravelOrder) => dispatch(travelOrderActions.runTruckManual(selectedTravelOrder)),
        sendEmailToTravelOrderCarrier: (carrier) => dispatch(travelOrderCarrierActions.sendEmailToCarrier(carrier)),
        editBOL: (bolData: formTypes.EditBOLFormValues) => dispatch(travelOrderActions.editBOL(bolData)),
        editPODs: (podData: formTypes.EditPODFormValues) => dispatch(travelOrderActions.editPODs(podData)),
        cancelNotPlannedTOWithoutPlannedTO: (cancelData: formTypes.CancelNotPlannedTOWithoutPlannedTOFormValues) =>
            dispatch(cancelTravelOderActions.cancelNotPlannedTOWithoutPlannedTO(cancelData)),
        stopCheckIn: (checkInData: formTypes.CheckInFormValues) =>
            dispatch(travelOrderActions.stopCheckIn(checkInData)),
        addTravelOrderOtherFiles: (data: Partial<formTypes.AddOtherFilesTOFormValues>) =>
            dispatch(travelOrderActions.addTravelOrderOtherFiles(data)),
        removeTravelOrderOtherFileByID: (fileID: number) =>
            dispatch(travelOrderActions.removeTravelOrderOtherFileByID(fileID)),
        addBillOfLading: (pickupBolData: formTypes.AddBillOfLadingModalFormValues) =>
            dispatch(travelOrderActions.addBillOfLading(pickupBolData)),
        addProofOfDelivery: (podData: formTypes.ProofOfDeliveryModalFormValues) =>
            dispatch(travelOrderActions.addProofOfDelivery(podData)),
        finishTravelOrder: (finishData: formTypes.FinishModalFormValues) =>
            dispatch(travelOrderActions.finishTravelOrder(finishData)),
        editDeadheadDetails: (deadheadData: formTypes.EditDeadheadDetailsFormValues) =>
            dispatch(travelOrderActions.editTravelOrderDeadheadDetails(deadheadData)),
        editDeadheadFiles: (deadheadData: formTypes.EditDeadheadFilesFormValues) =>
            dispatch(travelOrderActions.editTravelOrderDeadheadFiles(deadheadData)),
        editCheckIn: (checkInData: formTypes.EditCheckInFormValues) =>
            dispatch(travelOrderActions.editCheckIn(checkInData)),
        editCheckOut: (checkOutData: formTypes.EditCheckOutFormValues) =>
            dispatch(travelOrderActions.editCheckOut(checkOutData)),
        cancelCheckOut: () => dispatch(travelOrderActions.cancelCheckOut()),
        cancelCheckIn: () => dispatch(travelOrderActions.cancelCheckIn()),
        cancelSignPOD: () => dispatch(travelOrderActions.cancelSignPOD()),
        cancelAddBOL: () => dispatch(travelOrderActions.cancelAddBOL()),
        cancelFinish: () => dispatch(travelOrderActions.cancelFinish()),
        cancelPlannedTravelOrder: () => dispatch(cancelTravelOderActions.cancelPlannedTravelOrder()),
        cancelNotPlannedTOWithPlannedTO: (cancelData: formTypes.CancelNotPlannedTOWithPlannedTOFormValues) =>
            dispatch(cancelTravelOderActions.cancelNotPlannedTOWithPlannedTO(cancelData)),
        clearState: () => dispatch(travelOrderActions.travelOrderActionCreators.clearTravelOrderState()),
        fetchTravelOrder: (id?: string | number) => dispatch(travelOrderActions.fetchTO(id)),
        sendTravelOrderEmailAttachments: (attachmentData: formTypes.EmailAttachmentFormValues) =>
            dispatch(travelOrderActions.sendTravelOrderEmailAttachments(attachmentData)),
        addPODsForTheAllDeliveries: (podData: formTypes.NotSignedPODFormValues) =>
            dispatch(travelOrderActions.addPODsForTheAllDeliveries(podData)),
        confirmDryRun: (confirmDryRunData: formTypes.DryRunConfirmFormValues) =>
            dispatch(dryRunTravelOderActions.confirmDryRun(confirmDryRunData)),
        cancelDryRun: () => dispatch(dryRunTravelOderActions.cancelDryRun()),
        setDryRun: (dryRunData: formTypes.DryRunModalFormValues) =>
            dispatch(dryRunTravelOderActions.setDryRun(dryRunData)),
        editDryRun: (dryRunData: formTypes.DryRunModalFormValues) =>
            dispatch(dryRunTravelOderActions.editDryRun(dryRunData)),
        redirectTo: (url: string) => history.push(url),
    };
};
