import {Pages} from 'types/Pages';
import * as pages from 'utils/data/pages';

type Paths<K extends string, T> = {[P in K]?: T};

const config: Paths<Pages, string> = {
    [pages.SETTLEMENTS]: `/${pages.SETTLEMENTS}/:type/:action?/:settlementNumber?`,
    [pages.TRUCKS_NEAR_BY]: `/${pages.TRUCKS_NEAR_BY}/:origin?/:radius?`,
    [pages.TRUCKS_MAP]: `/${pages.TRUCKS_MAP}/(truck)?/:truckNumber?`,
    [pages.INVOICES]: `/${pages.INVOICES}/:action?/:invoiceNumber?`,
    [pages.FACTORING_COMPANIES]: `/${pages.FACTORING_COMPANIES}`,
    [pages.CARRIERS]: `/${pages.CARRIERS}/:action?/:id?`,
    [pages.DRIVERS]: `/${pages.DRIVERS}/:action?/:id?`,
    [pages.RELEASE_NOTES]: `/${pages.RELEASE_NOTES}`,
    [pages.OWNERS]: `/${pages.OWNERS}/:action?/:id?`,
    [pages.FULL_ALLIANCE]: `/${pages.FULL_ALLIANCE}`,
    [pages.TRIP_MONITOR]: `/${pages.TRIP_MONITOR}`,
    [pages.DISPATCHERS]: `/${pages.DISPATCHERS}`,
    [pages.CUSTOMERS]: `/${pages.CUSTOMERS}`,
    [pages.LOGS]: `/${pages.LOGS}/:action?/:id?`,
    [pages.LOAD_BOARD]: `/${pages.LOAD_BOARD}`,
    [pages.MY_COMPANY]: `/${pages.MY_COMPANY}`,
    [pages.FACILITIES]: `/${pages.FACILITIES}`,
    [pages.NOT_FOUND]: `/${pages.NOT_FOUND}`,
    [pages.REPORTS]: `/${pages.REPORTS}`,
    [pages.TRUCKS]: `/${pages.TRUCKS}`,
    [pages.LOGIN]: `/${pages.LOGIN}`,
    [pages.LOADS]: `/${pages.LOADS}`,
};

export default config;
