import compact from 'lodash/compact';
import omit from 'lodash/omit';
import trim from 'lodash/trim';
import map from 'lodash/map';

import {isLoadHasTempControl} from 'core/entities/Load/modules/loadTypes';
import {Dispatcher} from 'core/entities/Dispatcher/types';
import Load, {LoadStop} from 'core/entities/Load/types';
import Customer from 'core/entities/Customer/types';
import {Agent} from 'core/entities/Agent';

import {convertToStopDataForApi, transformEmailsToApiRequest, transformGeneralNoteToApi} from '../utils';
import {CreateUpdateLoadParamsForApi, MetaData} from '../types';

type CreateDynamicFields = {
    bookedWithCustomer: Customer;
    bookedByDispatcher: Dispatcher;
    agent: Agent;
    stops: LoadStop[];
    dispatchersAssignToLoad: Dispatcher[];
    note: string;
    dispatcher_send_updates: boolean;
    agent_send_updates: boolean;
};

export default function ({
    load,
    metaLoadFiles: {files},
}: {
    load: Load;
    metaLoadFiles: {files: MetaData[]};
}): CreateUpdateLoadParamsForApi {
    const dynamicFields: (keyof CreateDynamicFields)[] = [
        'bookedByDispatcher',
        'bookedWithCustomer',
        'agent',
        'stops',
        'dispatchersAssignToLoad',
        'note',
        'dispatcher_send_updates',
        'agent_send_updates',
    ];

    const loadData = omit(load, dynamicFields) as Load;
    const dispatcherIdsAssignToLoad = load?.dispatchersAssignToLoad || [];
    const note = transformGeneralNoteToApi(load?.note);

    return {
        ...loadData,
        files,
        bookedWithCustomerId: load.bookedWithCustomer.id,
        bookedByDispatcherId: load.bookedByDispatcher.id,
        ...(load.agent ? {agentId: load.agent.id} : {}),
        dispatcherIdsAssignToLoad,
        truckWithTempControl: isLoadHasTempControl(load),
        stops: load.stops.map(convertToStopDataForApi),
        note,
        refNumbers: map(compact(loadData.refNumbers), trim),
        emailsToUpdate: transformEmailsToApiRequest(load),
    };
}
